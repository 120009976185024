import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatearFecha } from "../utiles/formatearCampos";
const TablaGanadorTombola = ({ ganadorHalcon, mostrarGanador }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500, height: "100%" }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                borderRight: "1px solid #ddd",
              }}
            >
              NOMBRE
            </TableCell>
            <TableCell
              style={{
                borderRight: "1px solid #ddd",
              }}
              align="left"
            >
              SUCURSAL
            </TableCell>
            <TableCell
              style={{
                borderRight: "1px solid #ddd",
              }}
              align="left"
            >
              TÉLEFONO
            </TableCell>
            <TableCell
              style={{
                borderRight: "1px solid #ddd",
              }}
              align="left"
            >
              FACTURA
            </TableCell>
            <TableCell
              style={{
                borderRight: "1px solid #ddd",
              }}
              align="left"
            >
              FECHA
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mostrarGanador && ganadorHalcon && (
            <TableRow
              key={ganadorHalcon.nombre}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{
                  borderRight: "1px solid #ddd",
                }}
                align="left"
              >
                <div className="tomNombreGanador"> {ganadorHalcon.nombre}</div>
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #ddd",
                }}
                align="left"
              >
                {ganadorHalcon.sucursal}
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #ddd",
                }}
                align="left"
              >
                {ganadorHalcon.telefono}
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #ddd",
                }}
                align="left"
              >
                <div className="tomNombreGanador"> {ganadorHalcon.factura}</div>
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid #ddd",
                }}
                align="left"
              >
                {formatearFecha(ganadorHalcon.fecha)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablaGanadorTombola;
