import {hacerPeticion, checkTokenValidity} from "./api.js";
import { formatearFecha, formaterNumero, formatearFechaHora } from "../utiles/formatearCampos.js";

async function obtenerDocumentosSalida() {
  try {
    const respuesta = await hacerPeticion("get", "Documents/getDocuments");
    return respuesta.map((elemento) => ({
      ...elemento,
      total: formaterNumero(elemento.total),
      fecha: formatearFecha(elemento.fecha),
    }));
  } catch (error) {
    throw error;
  }
}


async function detalleDocumentoSalida(datos) {
  try {
    const respuesta = await hacerPeticion(
      "post",
      "Documents/getDocumentDetail",
      datos
    );
    return respuesta.map((elemento) => ({
      ...elemento,
      importe: formaterNumero(elemento.importe),
      costo: formaterNumero(elemento.costo),
    }));
  } catch (error) {
    throw error;
  }
}

async function detalleCambioDePrecio(datos) { 
  try{
    const response = await hacerPeticion(
      "post", "DetalleCambioPrecio/GetDetalleCambioPrecio",
      datos
    );
    return response; 
  } catch (error) {
    throw error;
  }
}

async function probarToken () {
  try {
    const response = await checkTokenValidity("get","Users/CheckToken");
    if (response !== null) {
      return true;
    }

  } catch (error) {
    console.log("No autorizado");
  }
}

async function aplicarDocumentoSalida(datos) {
  try {
    const respuesta = await hacerPeticion(
      "post",
      "Documents/ApplyDocument/",
      datos
    );
    return respuesta;
  } catch (error) {
    throw error;
  }
}

async function inicioSesionOK(datos) {
  try {
    const respuesta = await hacerPeticion("post", "Users/Login", datos);
    return respuesta;
  } catch (error) {
    throw error;
  }
}

async function cerrarSesion() {
  try {
    const respuesta = await hacerPeticion("post", "Users/Logout");
    return respuesta;
  } catch (error) {
    throw error;
  }
}

async function obtenerTipoRifa() {
  try {
    const respuesta = await hacerPeticion("get", "Tombola/GetRaffleType");
    return respuesta;
  } catch (error) {
    throw error;
  }
}

async function insertarParticipantesTombola(datos) {
  try {
    const respuesta = await hacerPeticion(
      "post",
      "Tombola/insertTombolaParticipants",
      datos
    );
    return respuesta;
  } catch (error) {
    throw error;
  }
}

async function cantidadPaticipantes(datos) {
  try {
    const respuesta = await hacerPeticion(
      "post",
      "Tombola/GetQuantityParticipants",
      datos
    );
    return respuesta;
  } catch (error) {
    throw error;
  }
}

async function historialGanadoresTombola() {
  try {
    const respuesta = await hacerPeticion(
      "get",
      "Tombola/GetHistoricalTombola"
    );
    return respuesta.map((elemento) => ({
      ...elemento,
      momentoGanador: formatearFechaHora(elemento.momentoGanador),
    }));
  } catch (error) {
    throw error;
  }
}

async function obtenerSucursales() {
  try{
    const response = await hacerPeticion(
      "get",
      "Sucursal/GetSucursales"
    );
    return response
  } catch (error) {
    throw error;
  }
}

async function obtenerProveedores() { 
  try{
    const response = await hacerPeticion(
      "get",
      "Proveedores/GetProveedores"
    ); 
    return response;
  } catch (error) {
     throw error; 
  }
}

async function obtenerCategorias() {
  try{
    const response = await hacerPeticion(
      "get", 
      "Categoria/GetCategoriaPadres"          
    );
    return response;
  } catch (error) {
    throw error;
  }
}


async function obtenerSegmentosCliente () {

  try {
    const response = await hacerPeticion(
      "get", "SegmentosCliente/GetSegmentosCliente"
    );
    return response;
  } catch (error) {
    throw error;
  }
}


async function obtenerDocumentosCambioPrecio() {
  try{
    const response = await hacerPeticion(
      "get", "AutorizarCambioPrecio/GetDocumentosAutorizar"
    );
   return response.map((elemento) => ({
      ...elemento,
      fecha: formatearFecha(elemento.fechaDoc),
    }));
  } catch (error) {
    throw error;
  }
}

async function autorizarCambioDePrecio(datos){
  try{
    const respuesta = await hacerPeticion(
      "post",
      "Documents/ApplyDocument/",
      datos
    );

    return respuesta;
  } catch (error) {
    throw error;
  }
}

async function obtenerResumenVentasPorSucursal(datos) {
  try { 
    const response = await hacerPeticion(
      "post",
      "ResumenVentasSucursal/GetResumenVentasSucursal",
      datos
    ); 
    return response;
  } catch (error) {
    throw error;
  }
}

async function obtenerMargenVentas (datos) {
  try { 
    const response = await hacerPeticion ("post",
    "MargenesVentas/GetMargenesVentas",
    datos)
    ;
    return response;

  } catch (error) { 
    throw error;
  }
}

async function obtenerMetaVentas (datos) {
  try {
    const response = await hacerPeticion ("post",
     "MargenesVentas/GetMetaTotalPorCategoria", 
    datos); 
    return response;
  } catch (error) {
    throw error;
  }
}

async function obtenerResumenVentas(datos) {
  try {
    const response = await hacerPeticion(
      "post",
      "ResumenVentas/GetResumenVentas",
      datos
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function obtenerGanadorTombola() {
  try {
    const respuesta = await hacerPeticion("get", "Tombola/GetTombolaWinner");
    return respuesta
  } catch (error) {
    throw error;
  }
}

async function guardarGanadorTombola(datos) {
  try {
    const respuesta = await hacerPeticion(
      "post",
      "Tombola/SaveWinnerTombola",
      datos
    );
    return respuesta;
  } catch (error) {
    throw error;
  }
}

async function enviarCorreo(datos) {
  try {
    const respuesta = await hacerPeticion(
      "post",
      "Documents/EmailSent",
      datos
    );
    return respuesta;
  } catch (error) {
    throw error;
  }
}
export {
  obtenerDocumentosSalida,
  detalleDocumentoSalida,
  aplicarDocumentoSalida,
  inicioSesionOK,
  cerrarSesion,
  obtenerTipoRifa,
  insertarParticipantesTombola,
  cantidadPaticipantes,
  historialGanadoresTombola,
  obtenerGanadorTombola,
  guardarGanadorTombola,
  enviarCorreo,
  obtenerSucursales,
  obtenerResumenVentasPorSucursal,
  obtenerResumenVentas,
  obtenerDocumentosCambioPrecio,
  autorizarCambioDePrecio,
  detalleCambioDePrecio,
  obtenerProveedores,
  obtenerCategorias,
  obtenerSegmentosCliente,
  obtenerMargenVentas,
  obtenerMetaVentas,
  probarToken
};
