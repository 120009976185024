import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { historialGanadoresTombola } from "../sevicios/endpoint";
import SearchIcon from "@mui/icons-material/Search";
import Loading from "../componentes/Loading";
import {
  FormControl,
  Input,
  Grid,
  InputAdornment,
  InputLabel,
} from "@mui/material";

const columns = [
  { id: "nombre", label: "Nombre", minWidth: 170 },
  { id: "sucursal", label: "Sucursal", minWidth: 100 },
  { id: "telefono", label: "Telefono", minWidth: 170, align: "left" },
  { id: "factura", label: "Factura", minWidth: 170, align: "left" },
  {
    id: "momentoGanador",
    label: "MomentoGanador",
    minWidth: 170,
    align: "left",
  },
  { id: "rifa", label: "Rifa", minWidth: 170, align: "left" },
];

export default function HistorialTombola() {
  const [historial, setHistorial] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [busqueda, setBusqueda] = React.useState("");
  const [loading, setLoading] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const manejarBuscar = (event) => {
    setBusqueda(event.target.value);
    setPage(0); // Reiniciar la página al realizar una nueva búsqueda
  };

  React.useEffect(() => {
    historialGanadores();
  }, []);

  const historialGanadores = async () => {
    try {
      setLoading(true);
      setHistorial(await historialGanadoresTombola());
    } catch (error) {
      console.log("Error al obtener el historial de ganadores: " + error);
    } finally{
      setLoading(false);
    }
  };

  const filtrarHistorial = historial.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(busqueda.toLowerCase())
    )
  );

  return (
    <>
      {loading ? <Loading /> : null}
      <Grid container spacing={2} style={{ marginBottom: "25px" }}>
        <Grid item xs={12} className="contenido_titulo">
          Historial de Ganadores
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="buscar" sx={{ fontSize: "20px" }}>
              Buscar
            </InputLabel>
            <Input
              type="text"
              className="buscar"
              id="buscar"
              value={busqueda}
              onChange={manejarBuscar}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#7a7676",
                          color: "#fff",
                          borderRight:
                            index !== columns.length - 1
                              ? "1px solid #fff"
                              : "none",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filtrarHistorial
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  borderRight:
                                    index !== columns.length - 1
                                      ? "1px solid #ddd"
                                      : "none",
                                }}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filtrarHistorial.length} // Use la longitud del historial filtrado
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
