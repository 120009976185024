
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import "../hojasEstilos/tablaDetalleCambioPrecio.css";
import { useEffect, useState } from "react";

function TablaDetalle(props) {

  const [detalle, setDetalle] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [tipo, setTipos] = useState([]);

  useEffect(() => {
    setDetalle(props.detalle.sort((a,b) => a.codigo_Compuesto - b.codigo_Compuesto));
    setEncabezadoSegmentos(props.detalle);
    setTiposPrecio(props.detalle);
  }, [])

  const setEncabezadoSegmentos = async (detalleList) => {

    let segmentosSet = new Set();

    detalleList.forEach(detal => {

      segmentosSet.add(detal.segmento);

    });

    let segmentos = Array.from(segmentosSet);

    segmentos.sort();

    setSegmentos(segmentos);
    
  }

  const setTiposPrecio = async (detalleList) => {

    let tiposSet = new Set();

    detalleList.forEach(detal => {
      tiposSet.add(detal.tipo);
    })

    let tipos = Array.from(tiposSet);

    tipos.sort();

    setTipos(tipos);
    
  };

  const productosMostrados = new Set();
  let opciones = { style: 'decimal', maximumFractionDigits: 2 }

  return (
    <div className="contenedor-table">
      <Table id="tableDelPrecio">
        <TableHead>
          <TableRow key={0} className="tableDel-encabezado-fila">
            <TableCell sx={{padding:"2px"}} className="tableDel-encabezado-tabla">Codigo</TableCell>
            <TableCell sx={{padding:"2px"}} className="tableDel-encabezado-tabla">Producto</TableCell>
            <TableCell sx={{padding:"2px"}} className="tableDel-encabezado-tabla">Costo</TableCell>
            <TableCell sx={{padding:"2px"}} className="tableDel-encabezado-tabla">Tipo</TableCell>
            {segmentos.map((segmento, index) => (
              <TableCell key={index} sx={{padding:"2px"}} className="tableDel-encabezado-tabla-segmentos">{segmento}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {detalle.map((detal, index) => {

            let tableRowContent;

            tableRowContent = (
              <TableRow  key={index}>
                <TableCell  className="fila">{detal.codigo_Compuesto}</TableCell>
                <TableCell  className="fila">{detal.descripcion_Producto}</TableCell>
                <TableCell  className="fila">C${detal.costoPromedio.toLocaleString('es-US', opciones)}</TableCell>
                <TableCell  className="contenedor-tipos" >
                  {tipo.map(tipos => (
                    <TableRow sx={{display:"flex"}} className="contenedor-tipos" key={tipos}>
                      <TableCell className="celda-tipo" align="left">{tipos}</TableCell>
                    </TableRow>                    
                  ))}
                </TableCell>
                {segmentos.map(segmento => (
                  <TableCell  className="fila" key={segmento}>
                    {tipo.map(tipos => (
                      <TableRow sx={{display:"flex"}} key={tipos}>
                        {detalle.map((dt, index) => {
                          if (dt.tipo === tipos && dt.segmento === segmento && dt.codigo_Compuesto === detal.codigo_Compuesto) {
                            return (
                              <TableCell key={index} sx={{textAlign:"right"}} className="celda-monto" >{dt.monto}</TableCell>
                            )
                          }
                        })}
                      </TableRow>
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            );
            
            if(!productosMostrados.has(detal.codigo_Compuesto)){
              productosMostrados.add(detal.codigo_Compuesto);
              return tableRowContent
            };

          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default TablaDetalle;