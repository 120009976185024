import { store } from "../redux/store";
import { reiniciarAutenticacion } from "../redux/autenticacionSlice";
import { alertaMensaje } from "../utiles/alertas";
import axios from "axios";



const API_BASE_URL = process.env.REACT_APP_API_URL;

// Función genérica para realizar solicitudes
async function hacerPeticion(method, endpoint, datos = null) {
  const estadoActual = store.getState();
  const token = estadoActual.seguridad.token
  const headers = {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
  };

  try {
    const respuesta = await axios({
      method,
      url: `${API_BASE_URL}/${endpoint}`,
      headers,
      data: datos,
    });
    if (endpoint === "Users/Logout") {
      store.dispatch(reiniciarAutenticacion(null));
    }

    return respuesta.data;
  } catch (error) {
    store.dispatch(reiniciarAutenticacion(null));
    console.error(`Error en la solicitud ${method.toUpperCase()} ${endpoint}:`, error);
    if (error && error.message === "Network Error") {
      alertaMensaje("Error de red", "Problemas al conectarse con el servidor", "error");
    }
    throw error;
  }
}

async function checkTokenValidity(method, endpoint, datos = null) {
  const estadoActual = store.getState();
  const token = estadoActual.seguridad.token
  const headers = {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
  };

  try {
    const respuesta = await axios({
      method,
      url: `${API_BASE_URL}/${endpoint}`,
      headers,
      data: datos,
    });
    if (endpoint === "Users/Logout") {
      store.dispatch(reiniciarAutenticacion(null));
    }

    return respuesta.data;
  } catch (error) {

    if (error.response && error.response.status === 401) {
      store.dispatch(reiniciarAutenticacion(null))
    }

    else if (error.message === "Network Error") {

      alertaMensaje("Error de red", "Problemas al conectarse con el servidor", "error");

    } else {
      console.error(error)
    }

    throw error;
  }
}

export  {hacerPeticion, checkTokenValidity};