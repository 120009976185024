import React, { useEffect, useState } from "react";
import '../hojasEstilos/tablaMargenesVentas.css'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function TablaMargenesVentas(props) {

  let opciones = { style: 'decimal', minimumFractionDigits:2 , maximumFractionDigits: 2  };

  const [margenVentas, setMargenVentas] = useState([]);
  const [totalMargenes, setTotalMargenes] = useState([]);

  useEffect(() => {
    setearMargenVentas();
    setTotales(props.margenVentas);    
  }, [])

  

  const setearMargenVentas = async () => {
    setMargenVentas(props.margenVentas);
  }

  const setTotales = async (ListVentas) => {
    
    let resumenTotal = [];

    var resumen = {
      Importe: 0, 
      Descuento: 0, 
      Total: 0, 
      Costo: 0, 
      Utilidad: 0,
      Margen:0
    };

    ListVentas.map( venta => {
      resumen.Importe += venta.importe
      resumen.Descuento += venta.descuento
      resumen.Total += venta.total
      resumen.Costo += venta.costo
      resumen.Utilidad += venta.utilidad       
    });

    resumen.Margen = (resumen.Total-resumen.Costo) / resumen.Total;


    resumenTotal.push(resumen);

    setTotalMargenes(resumenTotal);

  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <div className="contenedor-principal-tmv">
      <div className="contenedor-encabezado-tmv">
        <h1><b>{props.Empresa}</b></h1>
        <h2><b>{props.Sucursal}</b></h2>
        <div className="fila-contenedor-encabezado-tmv">
          <h3 className="encabezado-fechas">Margenes de Ventas del {props.fechaDesde} al {props.fechaHasta}</h3>
          <p className="encabezado-meta"><b>Meta: </b>  {props.metaVentas.toLocaleString('es-US', opciones)} </p>
        </div>
        <hr className="separator-tmv" />
      </div>
      <div className="contenedor-tabla-tmv">
        <TableContainer component={Paper} sx={{height:"calc(100vh - 50px)"}} >
          <Table stickyHeader aria-label="sticky table">
            <TableHead  > 
              <TableRow key={0} >
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'left'}}><b>Código</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'left'}}><b>Producto</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'right'}}><b>Unidades Vendidas</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'right'}}><b>Existencia Actual</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'right'}}><b>Tránsito o Producción</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'right'}}><b>Importe</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'right'}}><b>Descuento</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'right'}}><b>Total</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'right'}}><b>Costo</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'right'}}><b>Utilidad</b></TableCell>
                <TableCell sx={{color:"white",backgroundColor: '#4682b3', textAlign : 'right'}}><b>Margen</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {totalMargenes.map((total) => (
                <TableRow key={1} className="totalPrincipal-tabla-tmv">
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell sx={{color:"white", textAlign : 'right'}}><b>{total.Importe.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white", textAlign : 'right'}}><b>{total.Descuento.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white", textAlign : 'right'}}><b>{total.Total.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white", textAlign : 'right'}}><b>{total.Costo.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white", textAlign : 'right'}}><b>{total.Utilidad.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white", textAlign : 'right'}}><b>{(total.Margen * 100).toLocaleString('es-US', opciones) + '%' }</b></TableCell>                
              </TableRow>
              ))}              
              {margenVentas.map((venta) => (
               <StyledTableRow>
                  <StyledTableCell>{venta.codigo}</StyledTableCell>
                  <StyledTableCell>{venta.producto}</StyledTableCell>
                  <StyledTableCell sx={{textAlign: "right"}}>{venta.unidadesVendidas.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell sx={{textAlign: "right"}}>{venta.existenciaActual.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell sx={{textAlign: "right"}}>{venta.unidProc.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell sx={{textAlign: "right"}}>{venta.importe.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell sx={{textAlign: "right"}}>{venta.descuento.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell sx={{textAlign: "right"}}>{venta.total.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell sx={{textAlign: "right"}}>{venta.costo.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell sx={{textAlign: "right"}}>{venta.utilidad.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell sx={{textAlign: "right"}}>{(venta.margen * 100).toLocaleString('es-US', opciones) + '%' }</StyledTableCell>
               </StyledTableRow> 
              ))}
               {totalMargenes.map((total) => (
                <TableRow className="totalSecundario-tabla-tmv">
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell sx={{color:"white"}}><b>TOTALES:</b></TableCell>
                <TableCell></TableCell>
                <TableCell sx={{color:"white"}}><b>{total.Importe.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white"}}><b>{total.Descuento.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white"}}><b>{total.Total.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white"}}><b>{total.Costo.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white"}}><b>{total.Utilidad.toLocaleString('es-US', opciones)}</b></TableCell>                
                <TableCell sx={{color:"white"}}><b>{(total.Margen * 100).toLocaleString('es-US', opciones) + '%'}</b></TableCell>                
              </TableRow>
              ))}     
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>)
};


export default TablaMargenesVentas;