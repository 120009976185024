import React, { useEffect, useState } from "react";
import "../hojasEstilos/tablaResumenVentas.css";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function TablaResumenVentas(props) {

  const [ventas, setVentas] = useState([]);
  const [rowHeader, setRowHeader] = useState([]);
  const [Totales, setTotales] = useState([]);
  const [TotalIva, setTotalIva] = useState([]);

  useEffect(() => {
    setVentas(props.ventas);
    setEncabezados(props.ventas);
    setTotal(props.ventas);
    setTotalConIva(props.ventas);
  }, [props.ventas])

  const setEncabezados = async (ListVentas) => {

    let resumenVentas = [];

    ListVentas.forEach(venta => {

      var resumenVenta = {
        Sucursal: "",
        Importe: 0,
        Descuento: 0,
        SubTotal: 0,
        IVA: 0,
        Total: 0,
        Visitas: 0
      };

      resumenVenta.Sucursal = venta.empresa;
      venta.detalleTipoMov.forEach(ventaDet => {
        resumenVenta.Importe += ventaDet.importe
        resumenVenta.Descuento += ventaDet.descuento
        resumenVenta.SubTotal += ventaDet.subtotal
        resumenVenta.IVA += ventaDet.iva
        resumenVenta.Total += ventaDet.total
        resumenVenta.Visitas += ventaDet.visitas
      });

      resumenVentas.push(resumenVenta);
    }
    );
    setRowHeader(resumenVentas)
    
  }

  const setTotalConIva = async (ListVentas) => {

    let resumenTotal = []; 

    var resumenIva = { 
      SubTotalContado: 0, 
      IvaContado: 0, 
      TotalContado: 0,
      SubTotalCredito:0, 
      IvaCredito: 0, 
      TotalCredito:0, 
      GranTotal:0
    };

    ListVentas.forEach(venta => {
      resumenIva.SubTotalContado += venta.subtotalContado
      resumenIva.IvaContado += venta.ivaContado
      resumenIva.TotalContado += venta.totalContado
      resumenIva.SubTotalCredito += venta.subtotalCredito
      resumenIva.IvaCredito += venta.ivaCredito
      resumenIva.TotalCredito += venta.totalCredito
      resumenIva.GranTotal += venta.granTotal
    });

    resumenTotal.push(resumenIva);

    setTotalIva(resumenTotal);
  }

  const setTotal = async (ListVentas) => {

    let resumenVentas = [];

    var resumenVentasTotal =
    {
      Importe: 0,
      Descuento: 0,
      SubTotal: 0,
      IVA: 0,
      Total: 0,
      Visitas: 0
    };

    ListVentas.forEach(venta => {
      venta.detalleTipoMov.forEach(ventaDet => {
        resumenVentasTotal.Importe += ventaDet.importe
        resumenVentasTotal.Descuento += ventaDet.descuento
        resumenVentasTotal.SubTotal += ventaDet.subtotal
        resumenVentasTotal.IVA += ventaDet.iva
        resumenVentasTotal.Total += ventaDet.total
        resumenVentasTotal.Visitas += ventaDet.visitas
      })
    })

    resumenVentas.push(resumenVentasTotal);
    setTotales(resumenVentas)
  }

  let opciones = { style: 'decimal', maximumFractionDigits: 2 };
  let Moneda = props.moneda;



  return (
    <>
      <div className="contenedor-encabezado">
        <h1>Materiales de Construcción El Halcón RUC: 445-301159-0000F</h1>
        <h2>Resumen de Ventas x Sucursal del {props.fechaDesde} al {props.fechaHasta}</h2>
      </div>
      <div className="contenedor-tabla-principal">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow key={0} className="encabezado-tabla">
                <TableCell sx={{color:"white"}} />
                <TableCell sx={{color:"white"}}></TableCell>
                <TableCell sx={{color:"white"}}></TableCell>             
                <TableCell sx={{color:"white"}}><b>Importe</b></TableCell>
                <TableCell sx={{color:"white"}}><b>Descuento</b></TableCell>
                <TableCell sx={{color:"white"}}><b>Subtotal</b></TableCell>
                <TableCell sx={{color:"white"}}><b>IVA</b></TableCell>
                <TableCell sx={{color:"white"}}><b>Total</b></TableCell>
                <TableCell sx={{color:"white"}}><b>Visitas</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Totales.map((total) => (
                 (
                  <TableRow key={total.Total} className="tabla-totales">
                    <TableCell />
                    <TableCell sx={{color:"white"}}><b>Gran Total:</b></TableCell>
                    <TableCell sx={{color:"white"}}></TableCell>
                    <TableCell sx={{color:"white"}}><b>{Moneda}{total.Importe.toLocaleString('es-US', opciones)}</b></TableCell>
                    <TableCell sx={{color:"white"}}><b>{Moneda}{total.Descuento.toLocaleString('es-US', opciones)}</b></TableCell>
                    <TableCell sx={{color:"white"}}><b>{Moneda}{total.SubTotal.toLocaleString('es-US', opciones)}</b></TableCell>
                    <TableCell sx={{color:"white"}}><b>{Moneda}{total.IVA.toLocaleString('es-US', opciones)}</b></TableCell>
                    <TableCell sx={{color:"white"}}><b>{Moneda}{total.Total.toLocaleString('es-US', opciones)}</b></TableCell>
                    <TableCell sx={{color:"white"}}><b>{total.Visitas}</b></TableCell>
                  </TableRow>
                )
              ))}

              {rowHeader.map((row) =>
              (<Row
                sucursal={row.Sucursal}
                Importe={row.Importe}
                Descuento={row.Descuento}
                SubTotal={row.SubTotal}
                IVA={row.IVA}
                total={row.Total}
                visitas={row.Visitas}
                moneda={Moneda}
              >
              </Row>))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="contenedor-tabla-secundaria">
        <h2 className="titulo-tabla-secundaria">Resumen de Ventas + IVA</h2>
        <ResumenTable
          moneda ={Moneda}></ResumenTable>
        </div>
      </div>
    </>
  );

  function Row(props) {

    const [open, setOpen] = React.useState(false);
    let opciones = { style: 'decimal', maximumFractionDigits: 2 };
    let Moneda = props.moneda
    return (

      <React.Fragment>
        <>
          <TableRow key={props.sucursal} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {props.sucursal}
            </TableCell>
            <TableCell ></TableCell>
            <TableCell>{Moneda}{props.Importe.toLocaleString('es-US', opciones)}</TableCell>
            <TableCell >{Moneda}{props.Descuento.toLocaleString('es-US', opciones)}</TableCell>
            <TableCell >{Moneda}{props.SubTotal.toLocaleString('es-US', opciones)}</TableCell>
            <TableCell >{Moneda}{props.IVA.toLocaleString('es-US', opciones)}</TableCell>
            <TableCell >{Moneda}{props.total.toLocaleString('es-US', opciones)}</TableCell>
            <TableCell>{props.visitas}</TableCell>
           
          </TableRow>
          <TableRow key={props.total}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table aria-label="purchases">
                    <TableBody>
                      <TableRow key={props.SubTotal}>
                        <TableCell />
                        <TableCell>TipoMov</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">Importe</TableCell>
                        <TableCell align="center">Descuento</TableCell>
                        <TableCell align="center">Subtotal</TableCell>
                        <TableCell align="center">IVA</TableCell>
                        <TableCell align="center">Total</TableCell>                
                      </TableRow>
                      {ventas.map(venta => {
                        if (venta.empresa === props.sucursal) {
                          return venta.detalleTipoMov.map(ventaDet => (
                            <TableRow key={ventaDet.importe} sx={{ '& > *': { borderBottom: 'unset' } }}>
                              <TableCell />
                              <TableCell>{ventaDet.tipoMov}</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell align="center">{Moneda}{ventaDet.importe.toLocaleString('es-US', opciones)}</TableCell>
                              <TableCell align="center">{Moneda}{ventaDet.descuento.toLocaleString('es-US', opciones)}</TableCell>
                              <TableCell align="center">{Moneda}{ventaDet.subtotal.toLocaleString('es-US', opciones)}</TableCell>
                              <TableCell align="center">{Moneda}{ventaDet.iva.toLocaleString('es-US', opciones)}</TableCell>
                              <TableCell align="center">{Moneda}{ventaDet.total.toLocaleString('es-US', opciones)}</TableCell>
                            </TableRow>
                          ))
                        }
                        return null;
                      })
                      }

                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      </React.Fragment>
    );



  }

  function ResumenTable (props){
    let opciones = { style: 'decimal', maximumFractionDigits: 2 };
    let Moneda = props.moneda;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));


    return(
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow key={0} className="encabezado-tabla">
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell sx={{color:"white"}} align="center"><b>CONTADO</b></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell sx={{color:"white"}} align="center"><b>CREDITO</b></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow key={1} className="encabezado-tabla-detalle">
              <TableCell sx={{color:"white"}}><b>Sucursal</b></TableCell>
              <TableCell sx={{color:"white"}} align="center"><b>Subtotal</b></TableCell>
              <TableCell sx={{color:"white"}} align="center"><b>IVA</b></TableCell>
              <TableCell sx={{color:"white"}} align="center"><b>Total</b></TableCell>
              <TableCell sx={{color:"white"}} align="center"><b>Subtotal</b></TableCell>
              <TableCell sx={{color:"white"}} align="center"><b>IVA</b></TableCell>
              <TableCell sx={{color:"white"}} align="center"><b>Total</b></TableCell>
              <TableCell sx={{color:"white"}} align="center"><b>Gran Total</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ventas.map(venta =>{
              return (
                <StyledTableRow key={venta.granTotal}>
                  <StyledTableCell>{venta.empresa}</StyledTableCell>
                  <StyledTableCell align="center">{Moneda}{venta.subtotalContado.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell align="center">{Moneda}{venta.ivaContado.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell align="center">{Moneda}{venta.totalContado.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell align="center">{Moneda}{venta.subtotalCredito.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell align="center">{Moneda}{venta.ivaCredito.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell align="center">{Moneda}{venta.totalCredito.toLocaleString('es-US', opciones)}</StyledTableCell>
                  <StyledTableCell align="center"><b>{Moneda}{venta.granTotal.toLocaleString('es-US', opciones)}</b></StyledTableCell>
                </StyledTableRow>
              )
            })}
            {TotalIva.map(total => {
              return(
                <TableRow key={total.GranTotal} className="tabla-totales">
                <TableCell sx={{color:"white"}}><b>TOTALES:</b></TableCell>
                <TableCell sx={{color:"white"}} align="center"><b>{Moneda}{total.SubTotalContado.toLocaleString('es-US', opciones)}</b></TableCell>
                <TableCell sx={{color:"white"}} align="center"><b>{Moneda}{total.IvaContado.toLocaleString('es-US', opciones)}</b></TableCell>
                <TableCell sx={{color:"white"}} align="center"><b>{Moneda}{total.TotalContado.toLocaleString('es-US', opciones)}</b></TableCell>
                <TableCell sx={{color:"white"}} align="center"><b>{Moneda}{total.SubTotalCredito.toLocaleString('es-US', opciones)}</b></TableCell>
                <TableCell sx={{color:"white"}} align="center"><b>{Moneda}{total.IvaCredito.toLocaleString('es-US', opciones)}</b></TableCell>
                <TableCell sx={{color:"white"}} align="center"><b>{Moneda}{total.TotalCredito.toLocaleString('es-US', opciones)}</b></TableCell>
                <TableCell sx={{color:"white"}} align="center"><b>{Moneda}{total.GranTotal.toLocaleString('es-US', opciones)}</b></TableCell>
              </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )

  }

}




export default TablaResumenVentas;