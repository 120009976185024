import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
const ProtegerRutas = ({
  isAllowed,
  redirectTo 
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectTo} replace />;
  }
  return <Outlet />;
};
export default ProtegerRutas;