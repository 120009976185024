import "../hojasEstilos/detalleDocumento.css";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import TablaDetalle from "./TablaDetalle";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel,
  IconButton,
} from "@mui/material";
function DocumentoDetalle({
  abrir,
  cerrarModal,
  filtroModal,
  detalle,
  autorizarSalida,
}) {
  const style = {
    zIndex: 10,
  };
  return (
    <Dialog
      open={abrir}
      onClose={cerrarModal}
      maxWidth="lg"
      fullWidth
      style={style}
    >
      <DialogTitle
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          fontSize: "1.2rem",
        }}
      >
        Documento No {filtroModal.doc.noDoc}
        <IconButton edge="end" color="inherit" onClick={cerrarModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="contenedorPrin">
          <div className="caja cajaNormal">
            <InputLabel
              htmlFor="detalle_empresa"
              style={{ marginBottom: "1px" }}
            >
              Empresa
            </InputLabel>
            <TextField
              type="text"
              id="detalle_empresa"
              name="detalle_empresa"
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.empresa}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
          <div className="caja">
            <InputLabel htmlFor="detalle_fecha" style={{ marginBottom: "1px" }}>
              Fecha
            </InputLabel>
            <TextField
              type="text"
              id="detalle_fecha"
              name="detalle_fecha"
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.fecha}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
          <div className="caja">
            <InputLabel
              htmlFor="detalle_usuario"
              style={{ marginBottom: "1px" }}
            >
              Usuario
            </InputLabel>
            <TextField
              type="text"
              id="detalle_usuario"
              name="detalle_usuario"
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.usuario}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
          <div className="caja">
            <InputLabel
              htmlFor="detalle_sucursal"
              style={{ marginBottom: "1px" }}
            >
              Sucursal
            </InputLabel>
            <TextField
              type="text"
              id="detalle_sucursal"
              name="detalle_sucursal"
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.sucursal}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
          <div className="caja">
            <InputLabel htmlFor="detalle_monto" style={{ marginBottom: "1px" }}>
              Monto
            </InputLabel>
            <TextField
              type="text"
              id="detalle_monto"
              name="detalle_monto"
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.total}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
          <div className="caja cajaNormal">
            <InputLabel
              htmlFor="detalle_comentario"
              style={{ marginBottom: "1px" }}
            >
              Comentarios
            </InputLabel>
            <TextField
              type="text"
              multiline
              id="detalle_comentario"
              name="detalle_comentario"
              rows={2}
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.comentarios}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
          <TablaDetalle
            campo1={"Código"}
            campo2={"Descripción"}
            campo3={"Bodega"}
            campo4={"Cantidad"}
            campo5={"Costo"}
            campo6={"Costo Total"}
            datos={detalle.map((elemento) => ({
              data1: elemento.codigo,
              data2: elemento.descripcion,
              data3: elemento.bodega,
              data4: elemento.cantidad,
              data5: elemento.costo,
              data6: elemento.importe,
            }))}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => autorizarSalida(filtroModal.doc)}
          startIcon={<CheckCircle />}
        >
          Autorizar
        </Button>
        <Button
          variant="contained"
          onClick={cerrarModal}
          startIcon={<CloseIcon />}
          sx={{
            backgroundColor: "#CF0505",
            "&:hover": {
              backgroundColor: "#B80000",
            },
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DocumentoDetalle;
