import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import "../hojasEstilos/tablaDetalle.css";

export default function TablaDetalle({
  campo1,
  campo2,
  campo3,
  campo4,
  campo5,
  campo6,
  datos,
}) {
  return (
    <div className="contenedor-table">
      <Table id="tableDel">
        <TableHead>
          <TableRow>
            <TableCell>{campo1}</TableCell>
            <TableCell>{campo2}</TableCell>
            <TableCell>{campo3}</TableCell>
            <TableCell>{campo4}</TableCell>
            <TableCell>{campo5}</TableCell>
            {campo6 && <TableCell>{campo6}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {datos.map((registro, index) => (
            <TableRow key={index} className="tr">
              <TableCell>{registro.data1}</TableCell>
              <TableCell>{registro.data2}</TableCell>
              <TableCell>{registro.data3}</TableCell>
              <TableCell>{registro.data4}</TableCell>
              <TableCell>{registro.data5}</TableCell>
              {registro.data6 && <TableCell>{registro.data6}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
