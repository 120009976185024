const Ruleta = ({
  slotRef,
  fruits,
  rolling2,
  rolling,
  final1,
  final2,
  final3,
  final4,
  final5,
  final6,
  final7,
  final8,
  final9,
  habSelect,
  seleccion,
  handleChange,
  selectColor,
  descripcionRifa,
  tomBoton,
  intParticipantes,
  insertarParticipantes,
  roll,
  espera,
}) => {
  return (
    <>
      <div className="tomTitulo">Tómbola El Halcón</div>
      <div className="contenedorCajasTom">
        <div className="slot">
          <section>
            <div className="container" ref={slotRef[0]}>
              {fruits.map((fruit, i) => (
                <div key={i}>
                  {rolling2 ? <span>{fruit}</span> : <span>{final1}</span>}
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={slotRef[1]}>
              {fruits.map((fruit, i) => (
                <div key={i}>
                  {rolling2 ? <span>{fruit}</span> : <span>{final2}</span>}
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={slotRef[2]}>
              {fruits.map((fruit, i) => (
                <div key={i}>
                  {rolling2 ? <span>{fruit}</span> : <span>{final3}</span>}
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={slotRef[3]}>
              {fruits.map((fruit, i) => (
                <div key={i}>
                  {rolling2 ? <span>{fruit}</span> : <span>{final4}</span>}
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={slotRef[4]}>
              {fruits.map((fruit, i) => (
                <div key={i}>
                  {rolling2 ? <span>{fruit}</span> : <span>{final5}</span>}
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={slotRef[5]}>
              {fruits.map((fruit, i) => (
                <div key={i}>
                  {rolling2 ? <span>{fruit}</span> : <span>{final6}</span>}
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={slotRef[6]}>
              {fruits.map((fruit, i) => (
                <div key={i}>
                  {rolling2 ? <span>{fruit}</span> : <span>{final7}</span>}
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={slotRef[7]}>
              {fruits.map((fruit, i) => (
                <div key={i}>
                  {rolling2 ? <span>{fruit}</span> : <span>{final8}</span>}
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="slot">
          <section>
            <div className="container" ref={slotRef[8]}>
              {fruits.map((fruit, i) => (
                <div key={i}>
                  {rolling2 ? <span>{fruit}</span> : <span>{final9}</span>}
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>

      <div className="custom-input">
        <select
          disabled={habSelect}
          className="input"
          value={seleccion}
          onChange={handleChange}
          style={{
            fontFamily: "Quicksand, sans-serif",
            fontSize: 14,
            color: "#fff",
            ...selectColor,
          }}
        >
          {descripcionRifa.map(
            (des) => (
              // des.idFiltro !== 37 && (
              <option
                key={des.idFiltro}
                value={des.idFiltro}
                style={{ color: "black" }}
              >
                {des.descripcion}
              </option>
            )
            //)
          )}
        </select>
      </div>
      <div
        style={{
          ...tomBoton,
          opacity: rolling ? 0.5 : 1, // Reducimos la opacidad si rolling es true para hacerlo parecer desactivado
          pointerEvents: rolling ? "none" : "auto", // Evitamos que se reciban eventos de clics si rolling es true
          cursor: rolling ? "not-allowed" : "pointer", // Cambiamos el cursor si rolling es true
        }}
        className={rolling ? "roll rolling" : "roll"}
        onClick={
          !intParticipantes
            ? insertarParticipantes
            : rolling
            ? (event) => event.stopPropagation()
            : roll
        }
        disabled={rolling || espera}
      >
        {!intParticipantes
          ? "Cargar Facturas"
          : !rolling
          ? "Girar"
          : rolling && espera
          ? "¡Felicidades!"
          : "Buscando ganador"}
      </div>
    </>
  );
};

export default Ruleta;
