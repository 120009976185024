import React, { useState } from "react";
import { inicioSesionOK } from "../sevicios/endpoint.js";
import { useForm } from "react-hook-form";
import { agregarAutenticacion } from "../redux/autenticacionSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertaMensaje } from "../utiles/alertas.js";
import { TextField, InputAdornment } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NoEncryptionIcon from "@mui/icons-material/NoEncryption";
import "../hojasEstilos/login.css";
import Loading from "../componentes/Loading";

function Login() {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const navegacion = useNavigate();
  const [loading, colocarLoading] = useState(false);
  const IniciarSesion = async (data) => {
    try {
      colocarLoading(true);
      dispatch(agregarAutenticacion(await inicioSesionOK(data)));
      navegacion("/inicio");
    } catch (error) {
      console.error("Error al iniciar sesion:", error);
      reset();
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        alertaMensaje(error.response.data.error, "", "error");
      }
    } finally {
      colocarLoading(false);
    }
  };
  return (
    <>
      {loading ? <Loading /> : null}
      <div className="contenido">
        <div className="envoltura">
          <div className="contenedorLogo">
            <img
              alt=""
              src="https://elhalcon.com.ni/wp-content/uploads/2022/08/el-halcon-logo.png"
              className="logo"
            />
          </div>
          <form component="form" onSubmit={handleSubmit(IniciarSesion)}>
            <TextField
              type="text"
              required
              name="username"
              fullWidth
              label="Usuario"
              sx={{
                marginBottom: "20px",
                "& fieldset": {
                  borderColor: "#fff",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
              {...register("username")}
            />
            <TextField
              type="password"
              required
              name="password"
              fullWidth
              label="Contraseña"
              sx={{
                "& fieldset": {
                  borderColor: "#fff",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <NoEncryptionIcon />
                  </InputAdornment>
                ),
              }}
              {...register("password")}
            />
            <div className="input-box button">
              <input type="submit" value="Iniciar sesión" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default Login;
