import React, { useEffect, useState } from "react";
import "../hojasEstilos/tablaResumenVentasSucursal.css"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function TablaResumenVenta(props) {

  const [ventas, setVentas] = useState([]);
  const [rowHeader, setRowHeader] = useState([]);
  const [Totales, setTotales] = useState([]);

  useEffect(() => {
    setVentas(props.ventas);
    setEncabezados(props.ventas);
    setTotal(props.ventas);
  }, [])

  const setEncabezados = async (ListVentas) => {
    let tipoActual, Importe, Descuento, SubTotal, IVA, Total;
    let resumenVentas = [];
    ListVentas.map(venta => {
      var tipoMov = venta.tipoMov;
      if (tipoActual === undefined) {
        tipoActual = ""
      }
      if (tipoActual !== tipoMov) {
        tipoActual = tipoMov

        var resumenVenta = {
          TipoActual: tipoActual,
          Importe: 0,
          Descuento: 0,
          SubTotal: 0,
          IVA: 0,
          Total: 0
        };

        ListVentas.map(venta => {
          if (resumenVenta.TipoActual === venta.tipoMov) {
            resumenVenta.Importe += venta.importe
            resumenVenta.Descuento += venta.descuento
            resumenVenta.SubTotal += venta.subTotal
            resumenVenta.IVA += venta.iva
            resumenVenta.Total += venta.total
          }
        })

        resumenVentas.push(resumenVenta);
      }
    });
    setRowHeader(resumenVentas);
    return resumenVentas;
  }

  const setTotal = async (ListVentas) => {
    let tipoActual;
    let resumenVentas = [];

    var resumenVentaTotal =
    {
      Importe: 0,
      Descuento: 0,
      SubTotal: 0,
      IVA: 0,
      Total: 0
    };

    ListVentas.map(venta => {

      resumenVentaTotal.Importe += venta.importe
      resumenVentaTotal.Descuento += venta.descuento
      resumenVentaTotal.SubTotal += venta.subTotal
      resumenVentaTotal.IVA += venta.iva
      resumenVentaTotal.Total += venta.total
    }
    )

    resumenVentas.push(resumenVentaTotal);
    setTotales(resumenVentas);
    return resumenVentas;
  }

  let opciones = { style: 'decimal', maximumFractionDigits: 2 };
  let Moneda = props.moneda;

  


  // useEffect(() => {
  //   setEncabezados();
  // },[])

  return (

    <>
      <div className="contenedor-encabezado">
        <h1>Materiales de Construcción El Halcón RUC: 445-301159-0000F</h1>
        <h2>{props.Sucursal}</h2>
        <h2>Resumen de Ventas del {props.fechaDesde} al {props.fechaHasta}</h2>
        <hr className="separador" />
      </div>
      <div className="contenedor-tabla">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow key={0} className="encabezado-tabla">
                <TableCell sx={{color: "white"}} />
                <TableCell sx={{color: "white"}}></TableCell>
                <TableCell sx={{color: "white"}}></TableCell>
                <TableCell sx={{color: "white"}}></TableCell>
                <TableCell sx={{color: "white"}}><b>Importe</b></TableCell>
                <TableCell sx={{color: "white"}}><b>Descuento</b></TableCell>
                <TableCell sx={{color: "white"}}><b>SubTotal</b></TableCell>
                <TableCell sx={{color: "white"}}><b>IVA</b></TableCell>
                <TableCell sx={{color: "white"}}><b>Total</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Totales.map((total) =>
              {
                return (
                <TableRow key={1} className="tabla-totales">
                  <TableCell sx={{color: "white"}}></TableCell>
                  <TableCell sx={{color: "white"}}><b>Gran Total:</b></TableCell>
                  <TableCell sx={{color: "white"}} align="left"></TableCell>
                  <TableCell sx={{color: "white"}} align="left"></TableCell>
                  <TableCell sx={{color: "white"}} align="left"><b>{Moneda}{total.Importe.toLocaleString('es-US', opciones)}</b></TableCell>
                  <TableCell sx={{color: "white"}} align="left"><b>{Moneda}{total.Descuento.toLocaleString('es-US', opciones)}</b></TableCell>
                  <TableCell sx={{color: "white"}} align="left"><b>{Moneda}{total.SubTotal.toLocaleString('es-US', opciones)}</b></TableCell>
                  <TableCell sx={{color: "white"}} align="left"><b>{Moneda}{total.IVA.toLocaleString('es-US', opciones)}</b></TableCell>
                  <TableCell sx={{color: "white"}} align="left"><b>{Moneda}{total.Total.toLocaleString('es-US', opciones)}</b></TableCell>
                </TableRow>)})
              }

              {rowHeader.map((row) =>
              (<Row
                tipo={row.TipoActual}
                Importe={row.Importe}
                Descuento={row.Descuento}
                SubTotal={row.SubTotal}
                IVA={row.IVA}
                total={row.Total}
                moneda ={Moneda}
              />))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );






  function Row(props) {

    const [open, setOpen] = React.useState(false);
    let opciones = { style: 'decimal', maximumFractionDigits: 2 };
    let Moneda = props.moneda;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));


    return (

      <React.Fragment>

        <>
          <TableRow key={props.tipo} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {props.tipo}
            </TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left">{Moneda}{props.Importe.toLocaleString('es-US', opciones)}</TableCell>
            <TableCell align="left">{Moneda}{props.Descuento.toLocaleString('es-US', opciones)}</TableCell>
            <TableCell align="left">{Moneda}{props.SubTotal.toLocaleString('es-US', opciones)}</TableCell>
            <TableCell align="left">{Moneda}{props.IVA.toLocaleString('es-US', opciones)}</TableCell>
            <TableCell align="left">{Moneda}{props.total.toLocaleString('es-US', opciones)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table aria-label="purchases">
                    <TableBody>
                      <TableRow key={2}>
                        <TableCell />
                        <TableCell>NoDoc</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Cliente</TableCell>
                        <TableCell>Importe</TableCell>
                        <TableCell>Descuento</TableCell>
                        <TableCell>SubTotal</TableCell>
                        <TableCell>IVA</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                      {ventas.map(venta => {
                        if (venta.tipoMov === props.tipo) {
                          return (
                            <StyledTableRow key={venta.NoDoc} sx={{ '& > *': { borderBottom: 'unset' } }}>
                              <StyledTableCell />
                              <StyledTableCell>{venta.noDoc.toLocaleString('es-US', opciones)}</StyledTableCell>
                              <StyledTableCell>{venta.fechaDoc.toLocaleString('es-US', opciones)}</StyledTableCell>
                              <StyledTableCell>{venta.cliente.toLocaleString('es-US', opciones)}</StyledTableCell>
                              <StyledTableCell>{Moneda}{venta.importe.toLocaleString('es-US', opciones)}</StyledTableCell>
                              <StyledTableCell>{Moneda}{venta.descuento.toLocaleString('es-US', opciones)}</StyledTableCell>
                              <StyledTableCell>{Moneda}{venta.subTotal.toLocaleString('es-US', opciones)}</StyledTableCell>
                              <StyledTableCell>{Moneda}{venta.iva.toLocaleString('es-US', opciones)}</StyledTableCell>
                              <StyledTableCell>{Moneda}{venta.total.toLocaleString('es-US', opciones)}</StyledTableCell>
                            </StyledTableRow>);
                        }
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>

      </React.Fragment>
    );



  }

}


export default TablaResumenVenta;