import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/system";
import { cerrarSesion } from "../../sevicios/endpoint";
import { useSelector } from "react-redux";

const pages = ["Reportes", "Tómbola", "Autorizaciones"];
const settings = ["Cerrar sesión"];

const tombolaSubMenuItems = ["Girar", "Historial"];
const reportesSubMenuItems = ["Resumen de Ventas", "Margenes de Ventas"];
const AutoSubMenuItems = ["Salidas de Inventario", "Cambio de Precio"];

function BarraEncabezado({ temaEstablecer, esModoOscuro }) {
  const navegacion = useNavigate();
  const tema = useTheme();
  const menuColor = tema.estilosPersonalizados?.menuColor || {};
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElTombola, setAnchorElTombola] = React.useState(null); // Nuevo estado
  const [anchorReportes, setAnchorReportes] = React.useState(null);
  const [anchorAutoriza, setAnchorAutoriza] = React.useState(null);
  const usuario = useSelector((state) => state.seguridad.username);
  const logout = async () => {
    try {
      await cerrarSesion();
      navegacion("/");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      navegacion("/");
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenTombolaMenu = (event) => {
    // Nueva función para abrir el submenú de Tombola
    setAnchorElTombola(event.currentTarget);
  };

  const handleOpenReportMenu = (event) => {
    setAnchorReportes(event.currentTarget);
  };


  const handleOpenAutoMenu = (event) => {
    setAnchorAutoriza(event.currentTarget);
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseTombolaMenu = () => {
    // Nueva función para cerrar el submenú de Tombola
    setAnchorElTombola(null);
  };

  const handleCloseReportMenu = () => {
    setAnchorReportes(null);
  };

  const handleCloseAutorizacionesMenu = () => {
    setAnchorAutoriza(null);
  };

  const manejarLogout = () => {
    handleCloseUserMenu(); // Cierra el menú de usuario antes de hacer el logout
    logout(); // Llama a la función de logout
  };

  const manejarOpcionTombola = (opcion) => {
    if (opcion === "Girar") {
      // Redirige a la página de la tómbola
      navegacion("/tombola");
      handleCloseTombolaMenu();
    } else if (opcion === "Historial") {
      navegacion("/historialTombola");
      handleCloseTombolaMenu();
    }
    //  handleCloseTombolaMenu(); // Cerrar el submenú después de seleccionar una opción
  };

  const manejarOpcionReporte = (opcion) => {

    if (opcion === "Resumen de Ventas") {
      navegacion("/Reportes/resumenVentas");
      handleCloseReportMenu();
    }

    if (opcion === "Margenes de Ventas") {
      navegacion("/Reportes/margenesVentas");
      handleCloseReportMenu();
    }

  }

  const manejarOpcionAutoriza = (opcion) => {
    if (opcion === "Salidas de Inventario") {
      navegacion("/Autorizaciones/salidas");
      handleCloseAutorizacionesMenu();
    }

    if (opcion === "Cambio de Precio") {
      navegacion("/Autorizaciones/cambioPrecio")
      handleCloseAutorizacionesMenu();
    }
  }


  const { roles } = useSelector((state) => state.seguridad);
  const arregloRoles = roles ? roles.map((elemento) => elemento.idRol) : [];

  return (
    <AppBar
      position="fixed"
      className="fixed-app-bar"
      sx={{ zIndex: "5" }}
      style={menuColor}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            //href="/inicio"
            onClick={() => navegacion("/inicio")}
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "Quicksand",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            El Halcón
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {/* Roles de informatica y jefes */}
              {(arregloRoles.includes(1) || arregloRoles.includes(51)) &&
                pages.map(
                  (page) =>
                    page === "Autorizaciones" && (
                      <MenuItem key={page} onClick={handleOpenAutoMenu}>
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    )
                )}
              {(arregloRoles.includes(1) || arregloRoles.includes(7)) &&
                pages.map(
                  (page) =>
                    page === "Tómbola" && (
                      <MenuItem key={page} onClick={handleOpenTombolaMenu}>
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    )
                )}
              {(arregloRoles.includes(1) || arregloRoles.includes(51)) &&
                pages.map(
                  (page) =>
                    page === "Reportes" && (
                      <MenuItem key={page} onClick={handleOpenReportMenu}>
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    )
                )}
            </Menu>
          </Box>
          {/*<AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />*/}
          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={() => navegacion("/inicio")}
            // href="/inicio"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            EL Halcón
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>

              {(arregloRoles.includes(1) || arregloRoles.includes(51)) &&
                pages.map(
                  (page) =>
                    page === "Autorizaciones" && (
                      <Button
                        key={page}
                        onClick={handleOpenAutoMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        {page}
                      </Button>
                    )
                )}
              {(arregloRoles.includes(1) || arregloRoles.includes(7)) &&
                pages.map(
                  (page) =>
                    page === "Tómbola" && (
                      <Button
                        key={page}
                        onClick={handleOpenTombolaMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        {page}
                      </Button>
                    )
                )}
              {(arregloRoles.includes(1) || arregloRoles.includes(51)) &&
                pages.map(
                  (page) =>
                    page === "Reportes" && (
                      <Button
                        key={page}
                        onClick={handleOpenReportMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        {page}
                      </Button>
                    )
                )}
            </Box>
          </Box>
          <Tooltip title="Cambiar tema">
            <IconButton color="inherit" onClick={temaEstablecer}>
              {esModoOscuro ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={usuario}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar src="/broken-image.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) =>
                setting === "Cerrar sesión" ? (
                  <MenuItem key={setting} onClick={manejarLogout}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ) : (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                )
              )}
            </Menu>
          </Box>
          {/* Submenú de Tombola */}
          <Menu
            id="tombola-menu"
            anchorEl={anchorElTombola}
            open={Boolean(anchorElTombola)}
            onClose={handleCloseTombolaMenu}
          >
            {tombolaSubMenuItems.map((opcion) => (
              <MenuItem
                key={opcion}
                onClick={() => manejarOpcionTombola(opcion)}
              >
                <Typography textAlign="center">{opcion}</Typography>
              </MenuItem>
            ))}
          </Menu>
          {/* Submenu de Reportes */}
          <Menu
            id="reportes-menu"
            anchorEl={anchorReportes}
            open={Boolean(anchorReportes)}
            onClose={handleCloseReportMenu}>
            {reportesSubMenuItems.map((opcion) => (
              <MenuItem
                key={opcion}
                onClick={() => manejarOpcionReporte(opcion)}
              >
                <Typography textAlign="center">{opcion}</Typography>
              </MenuItem>
            ))}
          </Menu>
          {/* Submenu de Autorizaciones */}
          <Menu
            id="autorizaciones-menu"
            anchorEl={anchorAutoriza}
            open={Boolean(anchorAutoriza)}
            onClose={handleCloseAutorizacionesMenu}>
            {AutoSubMenuItems.map((opcion) => (
              <MenuItem
                key={opcion}
                onClick={() => manejarOpcionAutoriza(opcion)}
              >
                <Typography textAlign="center">{opcion}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default BarraEncabezado;
