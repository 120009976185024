import React, { useState, useEffect } from "react";
import { useTheme }  from "@mui/system";
import "../../hojasEstilos/resumenVentas.css"
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import CheckBox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl"
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { obtenerSucursales } from "../../sevicios/endpoint";
import { obtenerResumenVentasPorSucursal } from "../../sevicios/endpoint";
import { obtenerResumenVentas } from "../../sevicios/endpoint";
import TablaSucursal from "../../componentes/TablaResumenVentasSucursal.jsx";
import TablaVentas from "../../componentes/TablaResumenVentas.jsx";
import Swal from 'sweetalert2/src/sweetalert2.js';
import Loading from "../../componentes/Loading.jsx";


function ResumenVentas() {


  const tema = useTheme(); 
  const fondoOscuro = tema.estilosPersonalizados?.fondoTarjeta;

  const [Sucursales, setSucursales] = useState([]);
  const [idSucursal, setIdSucursal] = useState(99);
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState("");
  const [fechaDesdeSeleccionada, setFechaDesdeSeleccionada] = useState(new Date().toISOString().split('T')[0]);
  const [fechaHastaSeleccionada, setFechaHastaSeleccionada] = useState(new Date().toISOString().split('T')[0]);
  const [empresa, setEmpresa] = useState(0);
  const [checkDolares, setCheckDolares] = useState(0);
  const [checkExcluirEmpresas, setCheckExcluirEmpresas] = useState(1);
  const [mostrarTablaSucursal, setMostrarTablaSucursal] = useState(false);
  const [mostrarTablaVentas, setMostrarTablaVentas] = useState(false);
  const [ventas, setVentas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ListaSucursales();
  }, [])

  const ListaSucursales = async () => {
    try {
      setSucursales(await obtenerSucursales());
    } catch (error) {
      console.error("No se han podido cargar las sucursales");
    }
  }


  function OcultarTablas() {
    setMostrarTablaSucursal(false)
    setMostrarTablaVentas(false)
  }

  const solicitarVenta = async () => {

    

    var Indicador = ValidarEntradas();

    if (Indicador === true) {

      setLoading(true);

      if (idSucursal === 99) {
        try {
          let params = {
            desde: fechaDesdeSeleccionada,
            hasta: fechaHastaSeleccionada,
            verEmpresa: empresa,
            verDolares: checkDolares,
            excluirVentaEntreEmpresa: checkExcluirEmpresas
          };

          const response = await obtenerResumenVentas(params);
          
          setVentas(response);
          setMostrarTablaVentas(true);

        } catch (error) {
          DesplegarAlerta(error, "error");
        } finally {
          setLoading(false);
        }
      }
      else {
        try {
          let params = {
            desde: fechaDesdeSeleccionada,
            hasta: fechaHastaSeleccionada,
            idEmpresa: idSucursal,
            verEmpresa: empresa,
            verDolares: checkDolares,
            excluirVentaEntreEmpresa: checkExcluirEmpresas
          };

          const response = await obtenerResumenVentasPorSucursal(params);
          
          setVentas(response);
          setMostrarTablaSucursal(true);

        } catch (error) {
          DesplegarAlerta(error, "error");
        } finally {
          setLoading(false);
        }
      }

    }


    function ValidarEntradas() {
      var indicador = true;
      var fechaHoy = new Date().toISOString().split('T')[0];
      var text;

      if (fechaDesdeSeleccionada === null || fechaDesdeSeleccionada > fechaHoy || fechaDesdeSeleccionada === "") {
        text = "La Fecha Desde no puede estar vacia o ser mayor que hoy";
        DesplegarAlerta(text, "warning");
        indicador = false;
      }

      if (fechaHastaSeleccionada === null || fechaHastaSeleccionada === "" || fechaHastaSeleccionada < fechaDesdeSeleccionada) {
        text = "La Fecha Hasta no puede ser nulla o menor que la fecha desde";
        DesplegarAlerta(text, "warning");
        indicador = false;
      }

      if (idSucursal === null || idSucursal === "") {
        text = "Se tiene que seleccionar una sucursal";
        DesplegarAlerta(text, "warning");
        indicador = false;
      }

      if (empresa === null || empresa === "") {
        text="Se tiene que seleccionar una empresa";
        DesplegarAlerta(text, "warning");
        indicador = false;
      }

      return indicador;

    }
  }

  function DesplegarAlerta(text, tipo) {
    Swal.fire({
      icon: tipo,
      title: "Error",
      text: text
    });
  }


  const handleChangeSucursal = (event) => {
    OcultarTablas();
    if (event.target.value === 99) {
      setIdSucursal(99)
    } else {
      const Sucursal = Sucursales.find(sucursal => sucursal.id === event.target.value);
      setSucursalSeleccionada(Sucursal.sucursal);
      setIdSucursal(Sucursal.id);
    }

  }

  const handleDateDesdeChange = (event) => {
    OcultarTablas();
    const fechaDesde = event.target.value
    setFechaDesdeSeleccionada(fechaDesde);

  }

  const handleDateHastaChange = (event) => {
    OcultarTablas();
    const fechaHasta = event.target.value
    setFechaHastaSeleccionada(fechaHasta);

  }

  const handleEmpresaChange = (event) => {
    OcultarTablas();
    const Empresa = event.target.value;
    setEmpresa(Empresa);

  }

  const handleDolaresChange = (event) => {
    OcultarTablas();
    const checkStatus = event.target.checked;
    checkStatus ? setCheckDolares(1) : setCheckDolares(0);

  }

  const handleExcluirEmpresasChange = (event) => {
    OcultarTablas();
    const checkStatus = event.target.checked;
    checkStatus ? setCheckExcluirEmpresas(1) : setCheckExcluirEmpresas(0);


  }

  const handleMostrarTabla = () => {
    OcultarTablas()
    solicitarVenta();
  }

  return (
    <div className="contenedor-principal">
      <h1 className="titulo-principal" style={fondoOscuro} >Reporte Resumen de Ventas</h1>
      <div className="contenedor-parametros" style={fondoOscuro}>
        <FormControl variant="standard">
          <InputLabel id="input-sucursal-label">Sucursal</InputLabel>
          <Select
            id="input-sucursal"
            label="Sucursal"
            className="combobox-sucursal"
            onChange={handleChangeSucursal}
            value={idSucursal}
            defaultValue={99}
          >
            <MenuItem value={99}>Todas las sucursales</MenuItem>
            {Sucursales.map(sucursal => (
              <MenuItem value={sucursal.id} key={sucursal.id}>{sucursal.sucursal} </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard">
          <InputLabel id="input-desde-label">Desde:</InputLabel>
          <Input
            type="date"
            value={fechaDesdeSeleccionada}
            startAdornment={
              <InputAdornment position="start" />
            }
            onChange={handleDateDesdeChange}>
          </Input>
        </FormControl>
        <FormControl variant="standard">
          <InputLabel id="input-hasta-label">Hasta:</InputLabel>
          <Input
            type="date"
            value={fechaHastaSeleccionada}
            startAdornment={
              <InputAdornment position="start" />
            }
            onChange={handleDateHastaChange}>
          </Input>
        </FormControl>
        <FormControl variant="standard">
          <InputLabel id="input-empresa-label">Empresa</InputLabel>
          <Select
            id="input-empresa"
            label="Empresa"
            className="combobox-empresa"
            onChange={handleEmpresaChange}
            value={empresa}
            defaultValue={0}
          >
            <MenuItem value={0}>Ver ambas</MenuItem>
            <MenuItem value={1}>Juan Cajina</MenuItem>
            <MenuItem value={2}>Almacenes</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel control={<CheckBox onChange={handleDolaresChange} />} label="Dólares" />
        <FormControlLabel control={<CheckBox defaultChecked onChange={handleExcluirEmpresasChange} />} label="Excluir ventas entre empresas" />
        <Button
          variant="contained"
          onClick={handleMostrarTabla}>Mostrar</Button>
      </div>
      <div className="contenedor-tabla" style={fondoOscuro}>
        {loading ? <Loading /> : null}
        {mostrarTablaSucursal && (
          <TablaSucursal
            Sucursal={sucursalSeleccionada}
            fechaDesde={fechaDesdeSeleccionada}
            fechaHasta={fechaHastaSeleccionada}
            ventas={ventas}
            moneda={checkDolares ? "$" : "C$"}></TablaSucursal>)}
             {loading ? <Loading /> : null}
        {mostrarTablaVentas && (
          <TablaVentas
            Sucursal={sucursalSeleccionada}
            fechaDesde={fechaDesdeSeleccionada}
            fechaHasta={fechaHastaSeleccionada}
            ventas={ventas}
            moneda={checkDolares ? "$" : "C$"}></TablaVentas>)}
      </div>
    </div>
  );
}

export default ResumenVentas;