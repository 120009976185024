import "../hojasEstilos/detalleCambioPrecio.css";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import TablaDetalle from "./TablaDetalleCambioPrecio";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel,
  IconButton,
} from "@mui/material";
function DocumentoDetalle({
  abrir,
  cerrarModal,
  filtroModal,
  detalle,
  autorizarCambioPrecio
}) {
  const style = {
    zIndex: 10,
  };
  return (
    <Dialog
      open={abrir}
      onClose={cerrarModal}
      maxWidth="xl"
      
      // fullWidth
      style={style}
      className="dialog"
      sx={{height:"100%"}}
    >
      <DialogTitle
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          fontSize: "1.2rem",
        }}
      >
        Documento No {filtroModal.doc.noDoc}
        <IconButton edge="end" color="inherit" onClick={cerrarModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="contenedorPrin">
          <div className="caja cajaNormal">
            <InputLabel
              htmlFor="detalle_sucursal"
              style={{ marginBottom: "1px" }}
            >
              Sucursal
            </InputLabel>
            <TextField
              type="text"
              id="detalle_sucursal"
              name="detalle_sucursal"
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.empresa}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
          <div className="caja">
            <InputLabel htmlFor="detalle_fecha" style={{ marginBottom: "1px" }}>
              Fecha
            </InputLabel>
            <TextField
              type="text"
              id="detalle_fecha"
              name="detalle_fecha"
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.fecha}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
          <div className="caja">
            <InputLabel
              htmlFor="detalle_usuario"
              style={{ marginBottom: "1px" }}
            >
              Usuario
            </InputLabel>
            <TextField
              type="text"
              id="detalle_usuario"
              name="detalle_usuario"
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.usuario}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
          <div className="caja cajaNormal">
            <InputLabel
              htmlFor="detalle_comentario"
              style={{ marginBottom: "1px" }}
            >
              Comentarios
            </InputLabel>
            <TextField
              type="text"
              multiline
              id="detalle_comentario"
              name="detalle_comentario"
              rows={2}
              fullWidth
              size="small"
              variant="outlined"
              value={filtroModal.doc.comentarios}
              InputProps={{
                readOnly: true,
                style: { fontSize: "0.9rem" },
              }}
            />
          </div>
         
        </div>
        <div className="contenedor-tabla">
        <TablaDetalle
           detalle={detalle}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => autorizarCambioPrecio(filtroModal.doc)}
          startIcon={<CheckCircle />}
        >
          Autorizar
        </Button>
        <Button
          variant="contained"
          onClick={cerrarModal}
          startIcon={<CloseIcon />}
          sx={{
            backgroundColor: "#CF0505",
            "&:hover": {
              backgroundColor: "#B80000",
            },
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DocumentoDetalle;
