import "../hojasEstilos/autorizacion.css";
import CheckCircle from "@mui/icons-material/CheckCircle";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import { Typography, CardContent, Grid, Button, Card } from "@mui/material";
function TarjetaAutorizar({
  index,
  fondoTarjeta,
  letraNegrita,
  documento,
  autorizarSalida,
  colorletraBoton,
  detalleDocumento,
  abrirModal,
}) {
  return (
    <Grid item xs={12} md={4} lg={4} key={index}>
      <Card className="targeta" style={fondoTarjeta}>
        <CardContent>
          <div className="tarj">
            <Typography
              className="anuncio"
              gutterBottom
              variant="h6"
              component="div"
            >
              {" "}
              <span style={letraNegrita}>
                No Documento: {documento.noDoc}
              </span>{" "}
            </Typography>
            <p style={{ marginBottom: "3px" }}>
              <span style={letraNegrita}>Empresa:</span> {documento.empresa}
            </p>
            <p style={{ marginBottom: "3px" }}>
              <span style={letraNegrita}>Fecha:</span> {documento.fecha}
            </p>
            <p style={{ marginBottom: "3px" }}>
              <span style={letraNegrita}>Usuario:</span> {documento.usuario}
            </p>
            <p style={{ marginBottom: "3px" }}>
              <span style={letraNegrita}>Sucursal:</span> {documento.sucursal}
            </p>
            <p style={{ marginBottom: "3px" }}>
              <span style={letraNegrita}>Monto:</span> {documento.total}
            </p>
            <p style={{ marginBottom: "3px" }}>
              <span style={letraNegrita}>Comentarios:</span>{" "}
              {documento.comentarios}
            </p>
          </div>
          <div className="button-sta">
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "3px" }}
              data-id={`${documento.noDoc} ${documento.idTipoDoc} ${documento.idEmpresa}`}
              onClick={() => {
                autorizarSalida(documento);
              }}
              startIcon={<CheckCircle />}
            >
              Autorizar
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                backgroundColor: "#333",
                "&:hover": {
                  backgroundColor: "#444",
                },
              }}
              style={colorletraBoton}
              data-id={`${documento.noDoc} ${documento.idTipoDoc} ${documento.idEmpresa}`}
              data-bs-target="#salidasModal"
              onClick={() => {
                detalleDocumento(documento);
                abrirModal();
              }}
              startIcon={<RemoveRedEye />}
            >
              Detalle
            </Button>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
export default TarjetaAutorizar;
