import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: null,
  id: null,
  lastName: null,
  roles: [],
  token: null,
  username: null
};

export const autenticacionSlice = createSlice({
  name: "seguridad",
  initialState,
  reducers: {
    agregarAutenticacion: (state, action) => {
      const { firstName, id, lastName, roles, token, username } = action.payload;
      state.firstName = firstName;
      state.id = id;
      state.lastName = lastName;
      state.roles = roles;
      state.token = token;
      state.username = username;

    },
    reiniciarAutenticacion: (state, action) => {
      state.firstName = action.payload;
      state.id = action.payload;
      state.lastName = action.payload;
      state.roles = action.payload;
      state.token = action.payload;
      state.username = action.payload;
    },
  },
});
export const { agregarAutenticacion, reiniciarAutenticacion } = autenticacionSlice.actions;
export default autenticacionSlice.reducer;