import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const customSwalClass = {
  popup: "your-custom-swal-class",
};

const alertaMensaje = (titulo, texto, icono) => {
  MySwal.fire(titulo, texto, icono, customSwalClass);
}

const alertaAdvertencia = (titulo, texto, confirmacionBotonTexto, cancelacionBotonTexto, onConfirm) => {
  MySwal.fire({
    title: titulo,
    text: texto,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmacionBotonTexto,
    cancelButtonText: cancelacionBotonTexto,
    customClass: customSwalClass,
  }).then(async (result) => {
    if (result.isConfirmed) {
      onConfirm();
    }
  });
}
export { alertaAdvertencia, alertaMensaje };