import React, { useEffect, useState } from "react";
import { useTheme }  from "@mui/system";
import FormControl from "@mui/material/FormControl"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import InputAdornment from '@mui/material/InputAdornment';
import Button from "@mui/material/Button";
import CheckboxUI from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import TablaMargenesVentas from "../../componentes/TablaMargenesVentas";
import Loading from "../../componentes/Loading.jsx";

import { 
   obtenerSucursales,
   obtenerProveedores,
   obtenerCategorias,
   obtenerSegmentosCliente,
   obtenerMargenVentas,
   obtenerMetaVentas} from "../../sevicios/endpoint";
import Swal from 'sweetalert2/src/sweetalert2.js';

import "../../hojasEstilos/margenesVentas.css"


function MargenesVentas() {

  const tema = useTheme(); 
  const fondoOscuro = tema.estilosPersonalizados?.fondoTarjeta;

  const [margenVentas, setMargenVentas] = useState([]);
  const [mostrarTablaVentas, setMostrarTablaVentas] = useState(false);
  const [Sucursales, setSucursales] = useState([]);
  const [idSucursal, setIdSucursal] = useState(99);
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState('Todas las Sucursales');
  const [proveedores, setProveedor] = useState([]);
  const [idProveedor, setIdProveedor] = useState('0');
  const [categorias, setCategorias] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [segmentoSeleccionado, setSegmentosSeleccionado] = useState(99);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState([]);
  const [categoriaObject, setCategoriaObject] = useState([]);
  const [meta, setMeta] = useState([])
  const [fechaDesdeSeleccionada, setFechaDesdeSeleccionada] = useState(new Date().toISOString().split('T')[0]);
  const [fechaHastaSeleccionada, setFechaHastaSeleccionada] = useState(new Date().toISOString().split('T')[0]);
  const [empresa, setEmpresa] = useState(0);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState('AMBAS EMPRESAS');
  const [clase, setClase] = useState('todas');
  const [tipoFactura, setTipoFactura] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ListaSucursales();
    ListarProveedores();
    ListarCategorias();
    ListarSegmentos();
  }, [])

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function ValidarEntradas() {
    var indicador = true;
    var fechaHoy = new Date().toISOString().split('T')[0];
    var text;

    if (fechaDesdeSeleccionada === null || fechaDesdeSeleccionada > fechaHoy || fechaDesdeSeleccionada === "") {
      text = "La Fecha Desde no puede estar vacia o ser mayor que hoy";
      DesplegarAlerta(text, "warning");
      indicador = false;
    }

    if (fechaHastaSeleccionada === null || fechaHastaSeleccionada === "" || fechaHastaSeleccionada < fechaDesdeSeleccionada) {
      text = "La Fecha Hasta no puede ser nulla o menor que la fecha desde";
      DesplegarAlerta(text, "warning");
      indicador = false;
    }

    if (idSucursal === null || idSucursal === "") {
      text = "Se tiene que seleccionar una sucursal";
      DesplegarAlerta(text, "warning");
      indicador = false;
    }

    if (empresa === null || empresa === "") {
      text = ("Se tiene que seleccionar una empresa");
      DesplegarAlerta(text, "warning");
      indicador = false;
    }

    if (clase === null || clase === "") {
      text = ("Se tiene que seleccionar una clase");
      DesplegarAlerta(text, "warning");
      indicador = false;
    }

    if (categoriaObject.length === 0) {
      text = ("Se tiene que seleccionar al menos una categoria");
      DesplegarAlerta(text, "warning");
      indicador = false
    }

    return indicador;
  }

  function DesplegarAlerta(text, tipo) {
    Swal.fire({
      icon: tipo,
      title: "Error",
      text: text
    });
  }

  const solicitarMeta = async () => {
    try { 
      setLoading(true);

      let params = {
          desde: fechaDesdeSeleccionada,
          hasta: fechaHastaSeleccionada,
          idempresa : idSucursal,
          idproveedor:  idProveedor,
          tvpIdBodega : categoriaObject,
          idSegmento:  segmentoSeleccionado,
          tipoFactura: tipoFactura, 
          clasificacion: clase, 
          verEmpresa: empresa
      }

      const response = await obtenerMetaVentas (params);
      
      setMeta(response);


    } catch (error) {
      DesplegarAlerta(error, 'error');
    } finally {
      setLoading(false);
    }
  }

  const solicitarMargenes = async () => {

    var Indicador = ValidarEntradas();

    if (Indicador === true) {

      setLoading(true);

      try{

        let params  = {
          desde: fechaDesdeSeleccionada,
          hasta: fechaHastaSeleccionada,
          idempresa : idSucursal,
          idproveedor:  idProveedor,
          tvpIdBodega : categoriaObject,
          idSegmento:  segmentoSeleccionado,
          tipoFactura: tipoFactura, 
          clasificacion: clase, 
          verEmpresa: empresa
        } 

        

        const response = await obtenerMargenVentas (params);

        setMargenVentas(response);
        solicitarMeta();
        
        ocultarMostrarTabla(true);

      } catch (error) {
        DesplegarAlerta(error, 'error');
      } finally {
        setLoading(false);
      }    

    }

  }

  const ListaSucursales = async () => {
    try {
      setSucursales(await obtenerSucursales());

    } catch (error) {
      console.error("No se han podido cargar las sucursales")
    }
  }

  const ListarSegmentos = async () => {
    try {
      setSegmentos(await obtenerSegmentosCliente());
    } catch (error) {
      console.error("No se han podido cargar los segmentos")
    }
  }

  const ListarCategorias = async () => {
    try {

      setLoading(true);

      const cat = await obtenerCategorias();
      setCategorias(cat);
      const todasCategorias = cat.map(categoria => categoria.categoria);
      const todasCategoriasObj = cat.map( categoria => ({nombre: categoria.categoria}))
      setCategoriaSeleccionada(todasCategorias);
      setCategoriaObject(todasCategoriasObj);
    } catch (error) {
      console.log("No se han podido cargar las categorias")
    } finally {
      setLoading(false);
    }
  }



  const ListarProveedores = async () => {
    try {
      setLoading(true);
      setProveedor(await obtenerProveedores());

    } catch (error) {
      console.error("No se han podido cargar los proveedores");
    } finally { 
      setLoading(false);
    }
  }

  function ocultarMostrarTabla(value) {   
    setMostrarTablaVentas(value);
  } 

  const handleProveedorChange = (event) => {
    ocultarMostrarTabla(false);

    const Proveedor = proveedores.find(proveedor => proveedor.id === event.target.value);    
    setIdProveedor(Proveedor.id)
  }

  const handleChangeSucursal = (event) => {
    ocultarMostrarTabla(false);

    if (event.target.value === 99) {
      setIdSucursal(99)
      setSucursalSeleccionada('Todas las Sucursales');
    } else {
      const Sucursal = Sucursales.find(sucursal => sucursal.id === event.target.value);      
      setSucursalSeleccionada(Sucursal.sucursal);
      setIdSucursal(Sucursal.id);
    }
  }

  const handleDateDesdeChange = (event) => {
    ocultarMostrarTabla(false);

    const fechaDesde = event.target.value
    setFechaDesdeSeleccionada(fechaDesde)
  }

  const handleDateHastaChange = (event) => {
    ocultarMostrarTabla(false);

    const fechaHasta = event.target.value
    setFechaHastaSeleccionada(fechaHasta)
  }

  const handleEmpresaChange = (event) => {
    ocultarMostrarTabla(false);

    const Empresa = event.target.value;
    var EmpresaSeleccionada = ''; 

    if (Empresa  === 0) {
      EmpresaSeleccionada = 'AMBAS EMPRESAS'
    }

    if (Empresa === 1) {
      EmpresaSeleccionada = 'Materiales de Construcción El Halcón'
    }

    if (Empresa === 2) {
      EmpresaSeleccionada = 'Almacenes de la Construcción El Halcón S.A'
    }
    
    setEmpresaSeleccionada(EmpresaSeleccionada);
    setEmpresa(Empresa);
  }

  const handleClaseChange = (event) => {
    ocultarMostrarTabla(false);

    const Clase = event.target.value;
    setClase(Clase);
  }

  const handleTipoFacturaChange = (event) => {
    ocultarMostrarTabla(false);

    const tipoFactura = event.target.value;
    setTipoFactura(tipoFactura);
  }

  const handleSegmentoChange = (event) => {
    ocultarMostrarTabla(false);

    const Segmento = event.target.value;
    setSegmentosSeleccionado(Segmento);
  }

  const handleCategoriaChange = (event) => {
    ocultarMostrarTabla(false);

    const {
      target: { value },
    } = event;

    if (value.includes('SELECCIONAR TODAS')) {
      const todasCategoriasObj = categorias.map(categoria => ({nombre: categoria.categoria}));
      const todasCategorias =categorias.map(categoria => (categoria.categoria));

      if (categoriaSeleccionada.length === todasCategorias.length) {
        setCategoriaSeleccionada([])
        setCategoriaObject([])
      } else {
        setCategoriaSeleccionada(todasCategorias)
        setCategoriaObject(todasCategoriasObj)
      }

    } else {

      const categoriaArrayObj = (typeof value === 'string' ? value.split(',') : value).map(item => ({ nombre: item})); //Para convertir en un array de objetos.    
      const categoriaArray = (typeof value === 'string' ? value.split(',') : value)

      setCategoriaSeleccionada(categoriaArray);
      setCategoriaObject(categoriaArrayObj)
    }


  };

  const handleMostrarTabla = (event) => {
    ocultarMostrarTabla(false);
    solicitarMargenes();
  }

  return (
    
    <div className="contenedor-principal">
      <h1 className="titulo-principal" style={fondoOscuro} >Reporte Margenes de Ventas</h1>
      <div className="contenedor-parametros-margenesVentas" style={fondoOscuro}>
      {loading ? <Loading /> : null}
        <div className="contenedor-parametros-fila-mv">
          <FormControl variant="standard">
            <InputLabel id="input-sucursal-label">Sucursal</InputLabel>
            <Select
              id="input-sucursal"
              label="Sucursal"
              className="combobox-sucursal-mv"
              onChange={handleChangeSucursal}
              value={idSucursal}
              defaultValue={99}
            >
              <MenuItem value={99}>Todas las sucursales</MenuItem>
              {Sucursales.map(sucursal => (
                <MenuItem value={sucursal.id} key={sucursal.id}>{sucursal.sucursal}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="input-desde-label">Desde:</InputLabel>
            <Input
              type="date"
              className="input-fechaDesde-mv"
              value={fechaDesdeSeleccionada}
              onChange={handleDateDesdeChange}
              startAdornment={
                <InputAdornment position="start" />
              }
            >
            </Input>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="input-hasta-label">Hasta:</InputLabel>
            <Input
              type="date"
              value={fechaHastaSeleccionada}
              className="input-fechaHasta-mv"
              startAdornment={
                <InputAdornment position="start" />
              }
              onChange={handleDateHastaChange}>
            </Input>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="input-empresa-label">Empresa</InputLabel>
            <Select
              id="input-empresa"
              label="Empresa"
              className="combobox-empresa-mv"
              onChange={handleEmpresaChange}
              value={empresa}
              defaultValue={0}>
              <MenuItem value={0}>Ver Ambas</MenuItem>
              <MenuItem value={1}>Juan Cajina</MenuItem>
              <MenuItem value={2}>Almacenes</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="input-clase-label">Clase</InputLabel>
            <Select
              id="input-clase"
              label="clase"
              className="combobox-clase-mv"
              onChange={handleClaseChange}
              value={clase}
              defaultValue={'todas'}>
              <MenuItem value={'todas'}>Todas</MenuItem>
              <MenuItem value={'A'}>A</MenuItem>
              <MenuItem value={'B'}>B</MenuItem>
              <MenuItem value={'C'}>C</MenuItem>
              <MenuItem value={'D'}>D</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="contenedor-parametros-fila-mv">
          <FormControl variant="standard">
            <InputLabel id="input-proveedor-label">Proveedor</InputLabel>
            <Select
              id="input-proveedor"
              label="Proveedor"
              className="combobox-proveedor-mv"
              onChange={handleProveedorChange}
              value={idProveedor}
              defaultValue={'0'}
            >
              {proveedores.map(proveedor => (
                <MenuItem value={proveedor.id} key={proveedor.id}>{proveedor.proveedor}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" >
            <InputLabel id="demo-simple-select-standard-label">Categoria</InputLabel>
            <Select
              id="input-categoria"
              multiple
              value={categoriaSeleccionada}
              onChange={handleCategoriaChange}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              label="Categoria"
              className="combobox-categoria-mv"
            >
              <MenuItem key={"SELECCIONAR TODAS"} value={"SELECCIONAR TODAS"}>
                <CheckboxUI checked={categoriaSeleccionada.length > 0 && categoriaSeleccionada.length === categorias.length} />
                <ListItemText primary={"SELECCIONAR TODAS"} />
              </MenuItem>
              {categorias.map(categoria => (
                <MenuItem key={categoria.categoria} value={categoria.categoria}>
                  <CheckboxUI checked={categoriaSeleccionada.indexOf(categoria.categoria) > -1} />
                  <ListItemText primary={categoria.categoria} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="input-clase-label">Tipo Factura</InputLabel>
            <Select
              id="input-tipoFactura"
              label="TipoFactura"
              className="combobox-tipoFactura-mv"
              onChange={handleTipoFacturaChange}
              value={tipoFactura}
              defaultValue={0}>
              <MenuItem value={0}>Todo</MenuItem>
              <MenuItem value={1}>Contado</MenuItem>
              <MenuItem value={2}>Credito</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel id="input-clase-label">Segmento</InputLabel>
            <Select
              id="input-Segmento"
              label="clase"
              className="combobox-Segmento-mv"
              onChange={handleSegmentoChange}
              value={segmentoSeleccionado}
              defaultValue={99}>
              {segmentos.map(segmento => (
                <MenuItem value={segmento.id}>{segmento.nombre}</MenuItem>
              ))}
              <MenuItem value={99}>Todos los Segmentos</MenuItem>
            </Select>
          </FormControl>
          
          <Button
            className="input-btn-mv"
            variant="contained"
            onClick={handleMostrarTabla} 
            sx={{margin:" 5px 0px 0px 0px"}}           
          >
            Mostrar
          </Button>
        </div>
      </div>
      <div className="contenedor-tabla-mv" style={fondoOscuro}>
        {loading ? <Loading /> : null}
        {mostrarTablaVentas && (
          <TablaMargenesVentas
           margenVentas={margenVentas}
           Empresa={empresaSeleccionada}
           Sucursal={sucursalSeleccionada}
           fechaDesde={fechaDesdeSeleccionada}
           fechaHasta={fechaHastaSeleccionada}
           metaVentas={meta.map(m => (
            m.meta
           ))}></TablaMargenesVentas>
        )}       
      </div>
    </div>
  );
}
export default MargenesVentas;