import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  obtenerDocumentosSalida,
  detalleDocumentoSalida,
  aplicarDocumentoSalida,
  enviarCorreo,
} from "../sevicios/endpoint.js";
import { alertaAdvertencia, alertaMensaje } from "../utiles/alertas.js";
import Box from "@mui/material/Box";
import {
  FormControl,
  Input,
  Grid,
  InputAdornment,
  Button,
  InputLabel,
} from "@mui/material";
import "../hojasEstilos/autorizacion.css";
import { formaterNumero, formatearFecha } from "../utiles/formatearCampos.js";
import { useTheme } from "@mui/system";
import Loading from "../componentes/Loading.jsx";
import SearchIcon from "@mui/icons-material/Search";
import DocumentoDetalle from "../componentes/DocumentoDetalle.jsx";
import TarjetaAutorizar from "../componentes/TarjetaAutorizar.jsx";


function Autorizacion() {
  const navegacion = useNavigate();
  const { username, firstName, lastName } = useSelector(
    (state) => state.seguridad
  );
  const tema = useTheme();
  const letraNegrita = tema.estilosPersonalizados?.letraNegrita;
  const tituloEncabezado = tema.estilosPersonalizados?.tituloEncabezado;
  const colorletraBoton = tema.estilosPersonalizados?.colorletraBoton;
  const fondoTarjeta = tema.estilosPersonalizados?.fondoTarjeta;
  const [abrir, setAbrir] = useState(false);
  const abrirModal = () => {
    setAbrir(true);
  };
  const cerrarModal = () => {
    setAbrir(false);
  };

  
  const [loading, setLoading] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [documentosPorPagina] = useState(5);
  const [busqueda, setBusqueda] = useState("");
  const [detalle, setDetalle] = useState([]);
  const [filtroModal, setFiltroModal] = useState({
    doc: [],
  });



  const obtenerDocumentos = useCallback(async () => {
    try {
      setLoading(true);
      setDocumentos(await obtenerDocumentosSalida());
    } catch (error) {
      console.error("Error al obtener documentos", error);
      navegacion("/");
    } finally {
      setLoading(false);
    }
  }, [setLoading, setDocumentos, navegacion]);

  useEffect(() => {
    obtenerDocumentos();
  }, [obtenerDocumentos]);

  const mostrarTarjetas = () => {
    const indiceInicio = (pagina - 1) * documentosPorPagina;
    const indiceFin = indiceInicio + documentosPorPagina;
    const datosFiltrados = documentos.filter((documento) => {
      const textoTarjeta = `${documento.noDoc} ${documento.empresa} ${documento.fecha} ${documento.usuario} ${documento.sucursal} ${documento.total} ${documento.comentarios}`;
      return textoTarjeta.toLowerCase().includes(busqueda.toLowerCase());
    });

    return datosFiltrados
      .slice(indiceInicio, indiceFin)
      .map((documento, index) => (
        <TarjetaAutorizar
          key={documento.noDoc}
          index={index}
          fondoTarjeta={fondoTarjeta}
          letraNegrita={letraNegrita}
          documento={documento}
          autorizarSalida={autorizarSalida}
          colorletraBoton={colorletraBoton}
          detalleDocumento={detalleDocumento}
          abrirModal={abrirModal}
        />
      ));
  };
  const detalleDocumento = async (documento) => {
    try {
      setLoading(true);
      setFiltroModal({
        doc: documento,
      });
      let data = {
        noDoc: documento.noDoc,
        idTipoDoc: documento.idTipoDoc, 
        idEmpresa: documento.idEmpresa,
      };
      setDetalle(await detalleDocumentoSalida(data));
    } catch (error) {
      console.error("Error al obtener el documento detalle", error);
    } finally {
      setLoading(false);
    }
  };

  const autorizarSalida = async (documento) => {
    alertaAdvertencia(
      "¿Quieres Autorizar la salida?",
      "¡Click, si estás seguro!",
      "¡Sí, Autorizar!",
      "Cancelar",
      () => ejecutar()
    );
    async function ejecutar() {
      try {
        setLoading(true);
        let data = {
          noDoc: documento.noDoc,
          idTipoDoc: documento.idTipoDoc,
          idEmpresa: documento.idEmpresa,
        };
        let dataCorreo = {
          correo: documento.correo,
          usuarioAutoriza: username,
          nombreAutoriza: firstName + " " + lastName,
          nombreSolicita: documento.usuario,
          noDoc: documento.noDoc,
          Subject: "Autorizacion Salida de Inventario",
          TipoDocumento: "Salida de Inventario"
        };
        const respuesta = await aplicarDocumentoSalida(data);
        alertaMensaje("¡Hecho!", respuesta.message, "success");
        if (documento.correo !== "") {
          // Si el usuario que realiza la solicitud tiene correo, entonces se le enviara un correo
          await enviarCorreo(dataCorreo);
        }
        obtenerDocumentos();
        cerrarModal();
      } catch (error) {
        console.error("Error al aplicar el documento", error);
        navegacion("/");
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePaginaAnterior = () => {
    if (pagina > 1) {
      setPagina(pagina - 1);
    }
  };

  const manejarPaginaSiguiente = () => {
    const totalPaginas = Math.ceil(
      documentos.filter((documento) => {
        const textoTarjeta = `${documento.noDoc} ${
          documento.empresa
        } ${formatearFecha(documento.fecha)} ${documento.usuario} ${
          documento.sucursal
        } ${formaterNumero(documento.total)} ${documento.comentarios}`;
        return textoTarjeta.toLowerCase().includes(busqueda.toLowerCase());
      }).length / documentosPorPagina
    );

    if (pagina < totalPaginas) {
      setPagina(pagina + 1);
    }
  };

  const manejarBuscar = (e) => {
    setBusqueda(e.target.value);
    setPagina(1);
  };
  return (
    <>
      {loading ? <Loading /> : null}
      <Grid container spacing={2} style={{ marginBottom: "25px" }}>
        <Grid
          item
          xs={12}
          className="contenido_titulo"
          style={tituloEncabezado}
        >
          Autorización de salidas de inventario
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="buscar" sx={{ fontSize: "20px" }}>
              Buscar
            </InputLabel>
            <Input
              type="text"
              className="buscar"
              id="buscar"
              value={busqueda}
              onChange={manejarBuscar}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {mostrarTarjetas()}
        {documentos.length > 0 && 
        (
          <Grid item xs={12}>
            <Box component="div" id="paginacion">
              <Button
                id="pagina-anterior"
                onClick={handlePaginaAnterior}
                style={tituloEncabezado}
              >
                {" "}
                Atrás
              </Button>
              <Box component="span" id="numero-pagina">
                {" "}
                Página {pagina} de{" "}
                {Math.ceil(
                  documentos.filter((documento) => {
                    const textoTarjeta = `${documento.noDoc} ${
                      documento.empresa
                    } ${formatearFecha(documento.fecha)} ${documento.usuario} ${
                      documento.sucursal
                    } ${formaterNumero(documento.total)} ${
                      documento.comentarios
                    }`;
                    return textoTarjeta
                      .toLowerCase()
                      .includes(busqueda.toLowerCase());
                  }).length / documentosPorPagina
                )}
              </Box>
              <Button
                id="pagina-siguiente"
                onClick={manejarPaginaSiguiente}
                style={tituloEncabezado}
              >
                {" "}
                Siguiente
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <DocumentoDetalle
        abrir={abrir}
        cerrarModal={cerrarModal}
        filtroModal={filtroModal}
        detalle={detalle}
        autorizarSalida={autorizarSalida}
      />
    </>
  );
}
export default Autorizacion;
