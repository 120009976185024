import { useTheme } from "@mui/system";
import React from "react";
import "../hojasEstilos/inicio.css";

function Inicio() {
  const tema = useTheme();
  const colorletraBoton = tema.estilosPersonalizados.colorletraBoton || {};
  return (
    <div className="contenedor">
      <h1 className="inicio-titulo" style={colorletraBoton}>
        Sistema de Autorización de salidas de Inventario, EL HALCÓN
      </h1>
    </div>
  );
}
export default Inicio;
