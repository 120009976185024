import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider, CssBaseline, Container } from "@mui/material";
import BarraEncabezado from "./componentes/Encabezado/BarraEncabezado.jsx";
import ProtegerRutas from "./utiles/protegerRutas.js";
import Autorizacion from "./paginas/Autorizacion.jsx";
import AutorizacionPrecio from "./paginas/AutorizaCambioPrecio.jsx"
import Inicio from "./paginas/Inicio.jsx";
import Login from "./paginas/Login.jsx";
import Tombola from "./paginas/Tombola.jsx";
import { modoClaro, modoOscuro } from "./utiles/temas.js";
import "./hojasEstilos/app.css";
import HistorialTombola from "./paginas/HistorialTombola.jsx";
import ReporteVentas from "./paginas/reportes/ResumenVentas.jsx";
import ReporteMargen from "./paginas/reportes/MargenesVentas.jsx";
import { probarToken } from "./sevicios/endpoint.js";

const App = () => {

  const { token, roles } = useSelector((state) => state.seguridad);
  const arregloRoles = roles ? roles.map((elemento) => elemento.idRol) : [];
  const [esModoOscuro, establecerModoOscuro] = useState(
    localStorage.getItem("esModoOscuro") === "true" || false
  );

  useEffect(() => {
    localStorage.setItem("esModoOscuro", esModoOscuro);
    ValidarToken();
  }, [esModoOscuro]);

  
  function ValidarToken() {
    try {
      probarToken();      
    } catch (error) {
      console.log("token no valido");      
    }
  }

  const tema = useMemo(
    () => (esModoOscuro ? modoOscuro : modoClaro),
    [esModoOscuro]
  );

  const temaEstablecer = () => {
    establecerModoOscuro(!esModoOscuro);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <ProtegerRutas
                redirectTo={"/inicio"}
                isAllowed={token === null} //Tengo token me voy  al inicio , no tengo token no hago nada
              />
            }
          >
            <Route
              path="/"
              element={
                <ThemeProvider theme={modoOscuro}>
                  <CssBaseline />
                  <Login />
                </ThemeProvider>
              }
            />
          </Route>
          <Route
            element={
              <ProtegerRutas redirectTo="/" isAllowed={token !== null} /> //tengo token no pasa nada, no tengo token voy a la raiz
            }
          >
            <Route
              path="/inicio"
              element={
                <ThemeProvider theme={tema}>
                  <CssBaseline />
                  <BarraEncabezado
                    temaEstablecer={temaEstablecer}
                    esModoOscuro={esModoOscuro}
                  />
                  <Inicio />
                </ThemeProvider>
              }
            />            
          </Route>
          <Route
            element={
              <ProtegerRutas
                redirectTo="/inicio"
                isAllowed={
                  token !== null && //Tengo token no pasa nada, no tengo token voy al inicio
                  (arregloRoles.includes(1) || arregloRoles.includes(51))
                }
              />
            }
          >
            <>              
              <Route
                path="/Autorizaciones/salidas"
                element={
                  <ThemeProvider theme={tema}>
                    <CssBaseline />
                    <BarraEncabezado
                      temaEstablecer={temaEstablecer}
                      esModoOscuro={esModoOscuro}
                    />
                    <Container
                      maxWidth={false}
                      style={{ marginTop: "80px", marginBottom: "10px" }}
                    >
                      <Autorizacion />
                    </Container>
                  </ThemeProvider>
                }
              />
              <Route
                path="/Autorizaciones/cambioPrecio"
                element={
                  <ThemeProvider theme={tema}>
                    <CssBaseline />
                    <BarraEncabezado
                      temaEstablecer={temaEstablecer}
                      esModoOscuro={esModoOscuro}
                    />
                    <Container
                      maxWidth={false}
                      style={{ marginTop: "80px", marginBottom: "10px" }}
                    >
                      <AutorizacionPrecio />
                    </Container>
                  </ThemeProvider>
                }
              />
              <Route 
                path="/Reportes/resumenVentas"
                element={
                  <ThemeProvider theme={tema}>
                    <CssBaseline />
                    <BarraEncabezado 
                      temaEstablecer={temaEstablecer}
                      esModoOscuro={esModoOscuro} />
                    <Container
                      maxWidth={false}
                      style={{marginTop: "80px", marginBottom: "10px"}}
                      >
                        <ReporteVentas />
                      </Container>
                  </ThemeProvider>
                }/>
              <Route
                path="/Reportes/margenesVentas"
                element={
                  <ThemeProvider theme={tema}>
                    <CssBaseline />
                    <BarraEncabezado 
                      temaEstablecer={temaEstablecer}
                      esModoOscuro={esModoOscuro} />
                    <Container
                      maxWidth={false}
                      style={{marginTop: "80px", marginBottom: "10px"}}
                      >
                        <ReporteMargen />
                      </Container>
                  </ThemeProvider>
                } />
            </>
          </Route>
          <Route
            element={
              <ProtegerRutas
                redirectTo="/inicio"
                isAllowed={
                  token !== null &&
                  (arregloRoles.includes(1) || arregloRoles.includes(7)) //no tengo token me manda al inicio
                }
              />
            }
          >
            <Route
              path="/tombola"
              element={
                <ThemeProvider theme={tema}>
                  <CssBaseline />
                  <BarraEncabezado
                    temaEstablecer={temaEstablecer}
                    esModoOscuro={esModoOscuro}
                  />
                  <Tombola />
                </ThemeProvider>
              }
            />
            <Route
              path="/historialTombola"
              element={
                <ThemeProvider theme={tema}>
                  <CssBaseline />
                  <BarraEncabezado
                    temaEstablecer={temaEstablecer}
                    esModoOscuro={esModoOscuro}
                  />
                  <Container
                    maxWidth={false}
                    style={{ marginTop: "80px", marginBottom: "10px" }}
                  >
                    <HistorialTombola />
                  </Container>
                </ThemeProvider>
              }
            />
          </Route>
          
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;


