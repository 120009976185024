import { createTheme } from "@mui/material/styles";

const baseFontSize = 14; // Tamaño base de la fuente en píxeles

const modoClaro = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#edf2fb"
    },
    primary: {
      main: "#2196F3",
    },
    secondary: {
      main: "#FF4081",
    },


  },
  typography: {
    fontFamily: 'Quicksand, sans-serif',
    fontSize: baseFontSize, // Establecer el tamaño base de la fuente
  },
  estilosPersonalizados: {
    letraNegrita: {
      color: "#000",
      fontWeight: 'bold',
    },
    tituloEncabezado: {
      color: "#4B4D4F"
    },
    menuColor: {
      backgroundColor: "#116cd6"
    },
    colorletraBoton: {
      color: "#fff"
    },
    fondoTarjeta: {
      backgroundColor: "#ffffff"
    },
    //Tombola HALCON
    tomContenedor: {
      backgroundColor: "#ffffff"
    },
    tomTombola: {
      backgroundColor: "#1d75f1"
    },
    tomRuleta: {
      backgroundColor: "#116cd6",
      border: "3px solid #000"
    },
    tomBoton: {
      backgroundColor: "#FFFF04",
      border: "3px solid #000",
      color: "#000"
    },
    selectColor: {
      border: "3px solid #000",
    }
  },
});

const modoOscuro = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#4D4D4D"
    },
    primary: {
      main: "#64B5F6",
    },
    secondary: {
      main: "#FF80AB",
    },
  },
  typography: {
    fontFamily: 'Quicksand, sans-serif',
    fontSize: baseFontSize, // Establecer el tamaño base de la fuente
  },
  estilosPersonalizados: {
    letraNegrita: {
      color: "#fff",
      fontWeight: 'bold',
    },
    tituloEncabezado: {
      color: "#fff",
    },
    menuColor: {
      backgroundColor: "#121313",
    },
    colorletraBoton: {
      color: "#fff"
    },
    fondoTarjeta: {
      backgroundColor: "#333"
    },
    //Tombola HALCON
    tomContenedor: {
      backgroundColor: "#3F3C3C"
    },
    tomTombola: {
      backgroundColor: "#2D2F30"
    },
    tomRuleta: {
      backgroundColor: "#1E1C1C",
      border: "3px solid #c7c1b5"
    },
    tomBoton: {
      backgroundColor: "#d84d2a",
      border: "3px solid #c7c1b5",
      color: "#fff"
    },
    selectColor: {
      border: "3px solid #c7c1b5",
    }
  },
});

export { modoClaro, modoOscuro };
